import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';
import {MULTILINGUAL_TO_TRANSLATIONS_MAP, translationPath} from '../constants';
import {IControllerConfig, IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {ProductApi} from '../services/ProductApi';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {ProductService} from '../services/ProductService';
import {getProductWidgetSettings} from '../commons/styleParamsService';
import {WidgetActionEnum, WidgetPresetIdEnum} from '../commons/enums';
import {
  IProductWidgetDTO,
  IProductWidgetStyleParams,
  IPropsInjectedByViewerScript,
  TranslationDictionary,
  IFedopsCustomParamsFull,
  IFedopsCustomParamsMandatory,
} from '../types/app-types';
import {PageMap, APP_DEFINITION_ID, StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {exposureEventForTestsParams} from '@wix/bi-logger-ec-sf';
import {FetchError} from '@wix/wixstores-client-core/dist/es/src/http/FetchError';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/AddToCartService';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/constants';
import {actualPrice, hasDiscount} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';

export class ProductWidgetStore {
  private readonly fedopsLogger;
  private isStartReported: boolean = false;
  private isExternalRequestsValid: boolean = true;
  private readonly productApi: ProductApi;
  private translations: TranslationDictionary;
  private multilingualService: MultilingualService;
  private readonly productService: ProductService;
  private product: IProductWidgetDTO;
  private readonly addToCartService: AddToCartService;

  constructor(
    public styleParams: IProductWidgetStyleParams,
    public publicData: IControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly compId: IWidgetControllerConfig['compId'],
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: StoresWidgetID.PRODUCT_WIDGET,
    });

    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
      this.isStartReported = true;
    }

    this.productApi = new ProductApi(this.siteStore);
    this.productService = new ProductService(siteStore, compId, externalId, this.fedopsLogger);
    this.addToCartService = new AddToCartService(siteStore, publicData);

    this.handleCurrencyChange();
  }

  private handleCurrencyChange() {
    let currency = this.siteStore.location.query.currency;

    this.siteStore.location.onChange(() => {
      if (currency !== this.siteStore.location.query.currency) {
        currency = this.siteStore.location.query.currency;
        this.setInitialState().catch(this.reportError);
      }
    });
  }

  public onAppLoaded = () => {
    /* istanbul ignore next: hard to test it */
    if (!isWorker() || (this.siteStore.isInteractive() && this.isStartReported)) {
      this.fedopsLogger.appLoaded(this.getFedopsCustomParams());
      if (this.siteStore.isSiteMode()) {
        this.reportBIOnAppLoaded();
      }
    }
    this.isStartReported = false;
  };

  private getFedopsCustomParams() {
    const settings = getProductWidgetSettings(this.styleParams);
    return {
      customParams: JSON.stringify(
        this.product
          ? ({
              align_image: settings.widgetDirection,
              align_text: settings.alignment,
              image_resize: settings.imageScaling,
              image_width: settings.visualWidth.split('%')[0],
              is_stretch_to_full: settings.fullWidth || null,
              is_zoom_on_hover: settings.extendOnHoverToggle,
              on_click: this.isActionNavigate() ? 'navigate' : 'add-to-cart',
              on_hover: settings.hoverState,
              product_guid: this.product.id,
              product_type: this.product.digitalProductFileItems.length ? 'digital' : 'physical',
              product_widget_type: this.getOrientation(),
              show_button: this.showButtonSetting(settings),
              show_divider: settings.separatorToggle,
              show_price: settings.priceToggle,
              show_product_name: settings.titleToggle,
              store_id: this.siteStore.storeId,
            } as IFedopsCustomParamsFull)
          : ({product_guid: null, store_id: this.siteStore.storeId} as IFedopsCustomParamsMandatory)
      ),
    };
  }

  private isActionNavigate() {
    return (
      this.styleParams.fonts.widgetAction.value === WidgetActionEnum.NAVIGATE ||
      this.addToCartService.getButtonState({price: actualPrice(this.product), inStock: true}) ===
        AddToCartState.DISABLED
    );
  }

  private readonly showButtonSetting = (settings): 'hover' | 'always' | 'never' => {
    if (!this.siteStore.isMobile() && settings.hoverButtonToggle) {
      return 'hover';
    }
    return settings.widgetButtonToggle ? 'always' : 'never';
  };

  private reportBIOnAppLoaded() {
    const settings = getProductWidgetSettings(this.styleParams);

    const eventData: exposureEventForTestsParams = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      testName: 'product_widget_loaded',
      is_eligible: true,
      type: JSON.stringify({
        orientation: this.getOrientation(),
        show_button: this.showButtonSetting(settings),
        button: settings.widgetAction,
        alignment: settings.alignment,
        image_resize: settings.imageScaling,
        show_title: settings.titleToggle,
        show_price: settings.priceToggle,
        show_divider: settings.separatorToggle,
      }),
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.biLogger.exposureEventForTests(eventData);
  }

  private getOrientation() {
    return this.widgetPreset === WidgetPresetIdEnum.HORIZONTAL ? 'horizontal' : 'vertical';
  }

  private getTexts() {
    return Object.keys(MULTILINGUAL_TO_TRANSLATIONS_MAP).reduce(
      (acc, translationKey) => {
        const multiligualKey = MULTILINGUAL_TO_TRANSLATIONS_MAP[translationKey];
        const override = this.multilingualService.get(multiligualKey);
        if (override) {
          acc[translationKey] = override;
        }
        return acc;
      },
      {...this.translations}
    );
  }

  private getProductWidgetTranslations(): Promise<TranslationDictionary> {
    return getTranslations(translationPath(this.siteStore.baseUrls.productWidgetBaseUrl, this.siteStore.locale));
  }

  private getDefaultProps() {
    return {
      cssBaseUrl: this.siteStore.baseUrls.productWidgetBaseUrl,
      onAppLoaded: this.onAppLoaded,
      horizontalLayout: this.widgetPreset === WidgetPresetIdEnum.HORIZONTAL && !this.siteStore.isMobile(),
      isInteractive: this.siteStore.isInteractive(),
      widgetPreset: this.widgetPreset,
      isMobile: this.siteStore.isMobile(),
      experiments: {},
      texts: this.getTexts(),
    };
  }

  private setEmptyStateProps() {
    this.setProps({
      ...this.getDefaultProps(),
      product: null,
      emptyState: true,
    } as IPropsInjectedByViewerScript);
  }

  public async setInitialState(): Promise<void> {
    const externalResponses = await Promise.all([
      this.getProductWidgetTranslations(),
      this.productApi.getData(this.externalId || '', this.compId),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]).catch((e) => {
      this.reportError(e);
      this.isExternalRequestsValid = false;
    });
    if (!this.isExternalRequestsValid) {
      return;
    }

    const [translations, gqlResponse, {url}]: any = externalResponses;
    const {data} = gqlResponse;

    //TODO: FOR LOGGING PURPOSES - Don't forget to erase
    /* istanbul ignore next: hard to test it */
    if (!data.appSettings) {
      const extraInfoLog = new FetchError('Got 200 from the server, but appSettings is undefined', {
        response: gqlResponse,
        responseData: data,
      });
      this.reportError(extraInfoLog);
    }

    this.translations = translations;
    this.multilingualService = new MultilingualService(
      /* istanbul ignore next: todo */
      this.publicData.COMPONENT || {},
      data.appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    /* istanbul ignore next: hard to test it */
    this.product = data?.catalog?.product;

    if (!this.product) {
      this.setEmptyStateProps();
      return;
    }

    const propsToInject: IPropsInjectedByViewerScript = {
      ...this.getDefaultProps(),
      emptyState: false,
      handleAddToCart: this.handleAddToCart,
      hasDiscount: this.isDisabled ? this.product.comparePrice > 0 : hasDiscount(this.product),
      isDisabled: this.isDisabled,
      isSEO: this.siteStore.seo.isInSEO(),
      navigate: this.navigate,
      product: this.product,
      productPageUrl: this.product && this.product.urlPart ? `${url}/${this.product.urlPart}` : '',
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      shouldFocusAddToCartButton: false,
    };
    this.setProps(propsToInject);
  }

  private get isDisabled() {
    return (
      this.addToCartService.getButtonState({price: actualPrice(this.product), inStock: true}) ===
      AddToCartState.DISABLED
    );
  }

  private updatePublicData(newPublicData: IControllerConfig['publicData']) {
    Object.keys(newPublicData.COMPONENT).forEach((key) => {
      this.publicData.COMPONENT[key] = newPublicData.COMPONENT[key];
    });
  }

  public updateState(
    newStyleParams: IProductWidgetStyleParams,
    newPublicData: IControllerConfig['publicData'] & {appSettings?: any}
  ): void {
    this.updatePublicData(newPublicData);
    this.styleParams = newStyleParams;
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);

    this.setProps({
      texts: this.getTexts(),
    });
  }

  private readonly handleAddToCart = async (): Promise<any> => {
    const eventId = this.siteStore.pubSubManager.subscribe(
      'Minicart.DidClose',
      () => {
        this.setProps({
          shouldFocusAddToCartButton: Math.random(),
        });

        this.siteStore.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      },
      true
    );

    return this.productService.handleClick(this.product, this.isActionNavigate());
  };

  private readonly navigate = async (): Promise<any> => {
    return this.productService.handleClick(this.product, true);
  };

  private get widgetPreset(): WidgetPresetIdEnum {
    return (this.publicData.COMPONENT && this.publicData.COMPONENT.presetId) || WidgetPresetIdEnum.VERTICAL;
  }
}
